@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.bs-header {
  background: #fff;
}

.broker-bs {
  overflow: hidden;
}

.broker-bs .uk-container {
  max-width: 1200px;
}

.bs-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
}

.bs-icon {
  width: 80px;
}

.bs-home .bs-banner .bs-banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.bs-banner-inner {
  text-align: center;
  color: #ffffff;
  max-width: 805px;
  width: 75%;
  position: relative;
  z-index: 3;
}

.bs-banner-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.bs-banner-inner p {
  font-size: 16px;
}

.bs-banner-inner h1 {
  font-size: 30px;
}

.bs-home .bs-banner-inner h1,
.bs-home .bs-banner-inner p {
  color: #fff;
}

.uk-button,
.uk-button-default {
  transition: 0.5s;
}

.bs-ready-button {
  transition: 0.5s;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 15px 0;
  background: transparent;
}

.bs-ready-button:hover {
  background: linear-gradient(-225deg, #b2803f, #78501b);
}

.bs-dark-section {
  background: #000;
}
.bs-dark-section h2,
.bs-dark-section .uk-button {
  color: #ffffff;
}

.bs-slider .slick-track {
  padding: 20px 0;
}
.bs-slider .slick-slide > div {
  padding: 0 20px;
}

.bs-slider .slick-prev:before {
  transform: rotate(-45deg) !important;
}

.bs-slider .slick-next:before {
  transform: rotate(135deg) !important;
  margin-top: 7px;
}

.bs-slider-item {
  position: relative;
  cursor: pointer;
}

.image-slider .bs-slider-item:hover .bs-slider-item-inner p {
  height: 200px;
  opacity: 1;
}

.bs-slider-item:hover .bs-slider-item-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.image-slider .bs-slider-item-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 480px;
}

.bs-slider-item-inner h3 {
  margin: 0;
}

.image-slider .bs-slider-item-inner p {
  padding: 0 30px;
  height: 0;
  opacity: 0;
  transition: 0.3s;
  margin-top: 20px;
}

.bs-slider-item-inner h3,
.bs-slider-item-inner p {
  color: #ffffff;
  z-index: 3;
}

.bs-slider-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 2;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.89) 100%) no-repeat
    center center;
}

.bs-slider .slick-prev:before,
.bs-slider .slick-next:before {
  color: #000;
  font-size: 30px;
  content: "⇱";
  display: block;
}

.text-slider .slick-track {
  margin-bottom: 40px;
  z-index: 3;
}

.text-slider h5,
.text-slider p,
.text-slider span,
.bs-footer .bs-text-link {
  color: #485658;
  text-decoration: none;
}

.bs-footer .bs-text-link:hover {
  color: #1abc9c;
}

.text-slider p {
  line-height: 30px;
  font-size: 18px;
  letter-spacing: 1.6px;
}

.text-slider h5 {
  margin: 15px 0 0 0;
  font-weight: 600;
  font-size: 15px;
}

.text-slider span {
  font-size: 13px;
}

.text-slider .bs-slider-item-inner p {
  padding: 0 40px;
}

.text-slider:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(90deg, white, transparent);
  z-index: 2;
}

.text-slider:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(90deg, transparent, white);
  z-index: 2;
}

.bs-footer {
  border-top: 1px solid #1abc9c;
  padding: 40px 0;
  text-align: center;
}

.bs-footer .bs-icons img {
  width: 30px;
}

.bs-heli .bs-banner {
  align-items: center;
  justify-content: flex-start;
}

.bs-heli .bs-banner-inner h1 {
  color: #000;
  font-weight: 600;
  font-size: 30px;
}
.bs-heli .bs-banner .bs-banner-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}

.bs-heli .bs-banner-inner {
  text-align: left;
  padding-left: 20px;
}

.bs-heli .bs-banner {
  position: relative;
  display: flex;
  height: 260px;
}

.bs-ach {
  display: flex;
  justify-content: center;
}

.bs-ach-inner {
  display: flex;
  width: 230px;
}
.bs-ach .bs-icon {
  width: 50px;
  margin-right: 15px;
}

.bs-ach .bs-icon img {
  width: 100%;
}

.bs-text-gold {
  background: -webkit-linear-gradient(0deg, #c7a074, #78501b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bs-text {
  font-weight: 800;
  color: #000;
}

.bs-heli .bs-section-title {
  font-size: 30px;
  text-align: center;
  font-weight: 700;
}

.bs-cards {
  padding: 0;
}

.bs-cards img {
  border-radius: 8px;
  margin-bottom: 15px;
}

.bs-cards .bs-text-gold {
  margin-top: 15px;
}

.bs-card {
  cursor: pointer;
}

.bs-mini-card {
  width: 100%;
  background: #f6f6f6;
  height: 130px;
  box-shadow: 0 15px 15px -15px #a9a9a9;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  cursor: pointer;
}

.bs-mini-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px -15px #a9a9a9;
}

.bs-mini-card-inner {
  display: flex;
  align-items: center;
  width: 220px;
}

.bs-mini-card .img-box {
  border-radius: 8px;
  width: 120px;
  height: 80px;
  margin-right: 20px;
}

.bs-image-box {
  position: relative;
  height: 470px;
  width: 100%;
}

.bs-image-box:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 15px;
}

.bs-image-box-text {
  position: absolute;
  bottom: 20px;
  z-index: 3;
  padding-left: 30px;
}

.bs-image-box-text h4 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
}

.bs-image-box-text span {
  font-size: 18px;
  font-weight: 500;
}

.bs-image-box-text h4,
.bs-image-box-text span {
  color: #fff;
}

.bs-image-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 1;
  border-radius: 15px;
}

.bs-gallery-card .bs-image-bg {
  z-index: 2;
}

.bs-gallery-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
}

.bs-gallery-card:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  opacity: 0;
  transition: 0.3s;
}

.bs-gallery-btn {
  position: absolute;
  bottom: -61px;
  width: 100%;
  border: 0;
  text-align: center;
  z-index: 3;
  text-transform: uppercase;
  height: 60px;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(90deg, #b2803f, #78501b);
  transition: 0.3s;
  cursor: pointer;
}

.bs-gallery-card:hover.bs-gallery-card:after {
  opacity: 1;
}

.bs-gallery-card:hover .bs-gallery-btn {
  bottom: -1px;
}

.bs-partner .bs-text {
  display: block;
  margin-bottom: 10px;
}

.bs-partner-img {
  position: relative;
  height: 135px;
}

.bs-partner-img .bs-image-bg {
  border-radius: 4px;
}

.bs-banner-iframe {
  transition: 0.1s;
}

.bs-heli .bs-banner-iframe .bs-banner-img {
  width: 100%;
}

.bs-heli .bs-banner-overlay {
  box-shadow: inset 0 0 15px 15px #fff;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
}

@media (min-width: 576px) {
  .bs-footer {
    text-align: left;
  }
  .bs-cards {
    padding: 0 10%;
  }

  .bs-ach-inner {
    width: 100%;
  }

  .bs-ach img {
    width: 50px;
  }
  .bs-heli .bs-section-title {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .bs-home .bs-banner {
    height: 550px;
  }
  .bs-heli .bs-banner {
    height: 500px;
  }
  .bs-banner-inner {
    max-width: 800px;
  }

  .bs-banner-inner p {
    font-size: 20px;
  }

  .bs-home .bs-banner-inner h1 {
    font-size: 60px;
  }

  .bs-heli .bs-banner-inner h1 {
    color: #000;
    font-weight: 600;
    font-size: 50px;
  }
}
